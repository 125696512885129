<template>
    <div class="configComponent">
        <div class="bg-white p-6 rounded-lg py-24 flex items-center justify-center" v-if="loadingState.client">
            <Loader />
        </div>
        <div v-else>
            <FormulateForm>
                <div class="flex flex-col gap-6">
                    <!-- Configuration -->
                    <div>
                        <h1 class="text-gray-700 font-semibold text-lg p-4 bg-primary-300 HeadingPart">Configuration</h1>
                        <div class="bg-white p-6 configDetails">
                            <div class="grid grid-cols-2 gap-6 configData">
                                <FormulateInput
                                    class="w-full"
                                    label="Retention Period (should be in Days)"
                                    placeholder="Retention Period (should be in Days)"
                                    type="number"
                                    validation="required|number|min:0|max:30000|number|matches:/^[+]?\d+$/"
                                    :validation-messages="{
                                        matches: 'Retention period should be a whole number.',
                                    }"
                                    v-model="configuration.retention_period"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                    @keypress="isIntegerNumber"
                                />

                                <FormulateInput
                                    class="w-full"
                                    label="Max Users"
                                    placeholder="Max Users"
                                    validation="required|number|min:0,number"
                                    type="number"
                                    v-model="configuration.max_users"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                    @keypress="isIntegerNumber"
                                />
                            </div>

                            <div class="grid grid-cols-2 gap-6">
                                <FormulateInput
                                    class="w-full"
                                    label="Max Candidates"
                                    placeholder="Max Candidates"
                                    validation="required|number|min:0,number"
                                    type="number"
                                    v-model="configuration.max_candidates"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                    @keypress="isIntegerNumber"
                                />

                                <FormulateInput
                                    class="w-full"
                                    label="Max Clients"
                                    placeholder="Max Clients"
                                    validation="required|number|min:0,number"
                                    type="number"
                                    v-model="configuration.max_clients"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                    @keypress="isIntegerNumber"
                                />

                                <div>
                                    <h2 class="mb-1 mt-1">Dashboards *</h2>
    
                                     <Dropdown
                                        placeholder="Dashboards"
                                        v-model="configuration.dashboards"
                                        :options="dashboards"
                                        multiple
                                        :colors="{
                                            text: '#282828',
                                            bg: 'white',
                                            svg: '#282828',
                                            border: '#bcbcbc',
                                        }"
                                        @input="(value) => setTenantDashboards(value)"
                                        :config="{ label: 'name', trackBy: 'name' }"
                                        maxWidth="100%"
                                        minWidth="100%"
                                        width="102px"
                                        height="20px"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FormulateForm>
        </div>
        <div class="flex mt-4 bg-white w-full justify-between p-4 buttonPanel">
            <Button
              text="Back"
              type="outline"
              @click="$emit('handleBackSection')"
            />
            <Button
              :loader="isFormSubmit"
              :text="`${$route.params.tenant_id ? 'Update & Next' : 'Save & Next'}`"
              type="primary" @click="handleSubmit"
            />
        </div>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import {getTenantDetails, getTenantDashboards, getAllDashboards}  from "../../edit-tenant/services";
import Loader from "@shared/loader";
import { isIntegerNumber } from "@/plugins/functions";
import Dropdown from "@/components/dropdown-base"

export default {
    name: "client-configuration",
    components: {
        Button,
        Loader,
        Dropdown
    },
    props: {
        clientDetails:{
            type: Object,
            default: () => {}
        },
        loadingStates:{
            type:Object,
            default: () => {}
        }
    },
    data() {
        return {
            configuration: {
                retention_period: 0,
                max_users: 0,
                max_candidates: 0,
                max_clients: 0,
                key: "configuration",
                dashboards: []
            },
            loadingState: {
                client: false,
            },
            clientId: null,
            dashboards: [],
            selectedDashboards: []
        };
    },
    async mounted() {
         const allDashboards = await getAllDashboards()
            allDashboards.data.forEach((res)=>{
                res.dashboard_id = res.id
                res.id = res.quicksight_id 
            });

         this.dashboards = allDashboards?.data;

         if (this.$route.params?.tenant_id) {
            this.loadingState.client = true;
            const {data} = await getTenantDetails(this.$route.params.tenant_id);
            const { retention_period, max_candidates, max_users, max_clients } = data;

            this.configuration = {
                retention_period,
                max_candidates,
                max_users,
                max_clients,
            };

            this.configuration["key"] = "configuration";

            await this.handleTenantDashboards();

            this.loadingState.client = false;
        }
    },
    computed:{
        isFormSubmit(){
            return this.loadingStates.formSubmit;
        }
    },
    methods: {
        isIntegerNumber,
        setTenantDashboards(value) {
            console.log(value)
        },
        async handleTenantDashboards() {
            const dashboardData = await getTenantDashboards(this.$route.params.tenant_id)
            if (dashboardData?.data?.dashboards) {
            dashboardData.data.dashboards.forEach((res)=>{
                const allDashboardData = this.dashboards.find(r=>r.id == res.dashboard_id)
                if (allDashboardData) {
                    res.dashboard_id = allDashboardData.dashboard_id
                }
                res.id = res.dashboard_id
            })
            this.configuration["dashboards"] = dashboardData.data.dashboards
            }
            else {
                this.configuration["dashboards"] = []
            }
        },
        checkInputError() {
            const { retention_period, max_candidates, max_users, max_clients } = this.configuration;
            return [retention_period, max_candidates, max_users, max_clients].some(el => +el <=0);
        },
        handleSubmit() {
            console.log(this.configuration)
            // let hasErrors = this.checkInputError();
            if (this.checkInputError()) {
                return this.$toast.error("Value should be greater than Zero");
            }

            if (!this.configuration.dashboards.length) {
                return this.$toast.error("Please select a dashboard to continue");
            } 

            this.$emit("submitForm", this.configuration);
        },
    },
};
</script>

<style lang="scss" scoped>
    .HeadingPart {
        padding: 10px;
        border-radius: 10px 10px 0px 0px;
        color: #000;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .configDetails {
        border-radius: 0px 0px 10px 10px;
        color: #282828;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .buttonPanel {
        position: absolute;
        left: 0;
    }
</style>
